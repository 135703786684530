import axios from 'axios';
import { saveAs } from 'file-saver';
import { stringify } from 'query-string';
import { store } from '../store';
import API_DOMAIN from './apiDomain';

async function downloadStudentStageWiseReport() {
  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/dashboard/report/stage-wise-excel`;

  const response = await axios.get(url, options);
  const data = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  saveAs(data, `student-stage-wise-list.xlsx`);
}

async function getInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-performance-report`;
  const response = await axios.post(url, filters, options);
  return response;
}

async function downInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

// newly added
async function downInstructorIncentiveReport(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/instructors/get-incentive/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/instructor-passing-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadList(request) {
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
  };

  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  try {
    const response = await axios.get(url, options);

    const data = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(data, `ticket-list${new Date().getTime()}.xlsx`);
  } catch (err) {
    console.log('error in await axios.get', err);
  }
}

async function downloadCustomerWaitingReport(request) {
  const { field, order } = request.sort;

  const query = {
    ...request.queryParams,
    _filters: JSON.stringify(request.filter),
    _sort: field,
    _order: order,
  };

  const options = {
    headers: {
      'Content-Type':
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'arraybuffer',
  };

  const userToken = request.token
    ? request.token
    : store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/${request.resource}?${stringify(query)}`;

  try {
    const response = await axios.get(url, options);
    const data = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(data, `Customer waiting report ${new Date().getTime()}.xlsx`);
  } catch (err) {
    console.log('error in await axios.get', err);
  }
}

async function getInternalExaminerPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/internal-examiner-passing-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downInternalExaminerPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/internal-examiner-passing-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadUpgradedStudentsReport(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/student/upgraded/report/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/first-attempt-passing-performance-report`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/first-attempt-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function getPreRegistrationReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/preregistration-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downPreRegistrationReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/admin/preregistration-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function witdrawReferralAmount(id, filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/withdraw-referral-amount/${id}`;

  const response = await axios.post(url, filters, options);
  console.log(response);
  return response;
}

async function getReferralWithdrawDetails(filters, id) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  console.log(userToken);
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/referral-bonus/user-report/${id}?${stringify(
    { ...filters }
  )}`;

  const response = await axios.get(url, options);
  console.log(response);
  return response;
}

async function downloadLpExpiryReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/lp-expiry-report/download`;

  const response = await axios.get(url, filters, options);
  return response;
}

async function downloadRtaFileExpiryReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/rta-expiry-report/download`;

  const response = await axios.get(url, filters, options);
  return response;
}

async function getEmaarDailySalesInfoLogs(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/emaar-sales/daily`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function getEmaarMonthlySalesInfoLogs(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/emaar-sales/monthly?${filters}`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downloadDailyEmaarSalesInfoLogs(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/emaar-sales/daily/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downloadMonthlyEmaarSalesInfoLogs(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/emaar-sales/monthly/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downloadExamDetials(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/integrator/v1/ExamDetails/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadExamResult(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/integrator/v1/ExamResult/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getEliteInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/elite-instructor-passing-report?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downEliteInstructorPassingReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/elite-instructor-passing-report/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getEliteFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/elite-first-attempt-passing-performance-report`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downEliteFirstAttemptPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/elite-first-attempt-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function getEliteInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }

  const url = `${API_DOMAIN}/user/v1/reports/elite-instructor-passing-performance-report`;
  const response = await axios.post(url, filters, options);
  return response;
}

async function downEliteInstructorPassingPerformanceReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/elite-instructor-passing-performance-report/download`;

  const response = await axios.post(url, filters, options);
  return response;
}

async function downPendingScheduleListing(filters) {
  const query = {
    _filters: JSON.stringify(filters),
  };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/schedule/v1/schedule/pending/download?${stringify(
    query
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function downloadRtaPaymentInfo(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/vnd.ms-excel',
    },
    responseType: 'arraybuffer',
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/integrator/v1/RTAPaymentInfo/download?${stringify(
    filters
  )}`;

  const response = await axios.get(url, options);
  return response;
}

async function getMilestonePaidReport(filters) {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const userToken = store.getState().authUser.token;
  if (userToken) {
    options.headers.Authorization = `Bearer ${userToken}`;
  }
  const url = `${API_DOMAIN}/user/v1/reports/milestone-wise-paid-report?${stringify(
    filters
  )}`;
  const response = await axios.get(url, options);
  return response;
}

export {
  downloadStudentStageWiseReport,
  getInstructorPassingReport,
  getInstructorPassingPerformanceReport,
  downInstructorPassingPerformanceReport,
  downInstructorIncentiveReport,
  downInstructorPassingReport,
  downloadList,
  getInternalExaminerPassingReport,
  downInternalExaminerPassingReport,
  downloadUpgradedStudentsReport,
  getFirstAttemptPassingPerformanceReport,
  downFirstAttemptPassingPerformanceReport,
  getPreRegistrationReport,
  downPreRegistrationReport,
  witdrawReferralAmount,
  getReferralWithdrawDetails,
  downloadLpExpiryReport,
  downloadRtaFileExpiryReport,
  getEmaarDailySalesInfoLogs,
  getEmaarMonthlySalesInfoLogs,
  downloadDailyEmaarSalesInfoLogs,
  downloadMonthlyEmaarSalesInfoLogs,
  downloadExamDetials,
  downloadExamResult,
  getEliteInstructorPassingReport,
  downEliteInstructorPassingReport,
  getEliteFirstAttemptPassingPerformanceReport,
  downEliteFirstAttemptPassingPerformanceReport,
  getEliteInstructorPassingPerformanceReport,
  downEliteInstructorPassingPerformanceReport,
  downloadCustomerWaitingReport,
  downloadRtaPaymentInfo,
  getMilestonePaidReport,
  downPendingScheduleListing,
};

export default {
  downloadStudentStageWiseReport,
  getInstructorPassingReport,
  getInstructorPassingPerformanceReport,
  downInstructorPassingPerformanceReport,
  downInstructorIncentiveReport,
  downInstructorPassingReport,
  downloadList,
  getInternalExaminerPassingReport,
  downInternalExaminerPassingReport,
  downloadUpgradedStudentsReport,
  getFirstAttemptPassingPerformanceReport,
  downFirstAttemptPassingPerformanceReport,
  getPreRegistrationReport,
  downPreRegistrationReport,
  witdrawReferralAmount,
  getReferralWithdrawDetails,
  downloadLpExpiryReport,
  downloadRtaFileExpiryReport,
  getEmaarDailySalesInfoLogs,
  getEmaarMonthlySalesInfoLogs,
  downloadDailyEmaarSalesInfoLogs,
  downloadMonthlyEmaarSalesInfoLogs,
  downloadExamDetials,
  downloadExamResult,
  getEliteInstructorPassingReport,
  downEliteInstructorPassingReport,
  getEliteFirstAttemptPassingPerformanceReport,
  downEliteFirstAttemptPassingPerformanceReport,
  getEliteInstructorPassingPerformanceReport,
  downEliteInstructorPassingPerformanceReport,
  downloadCustomerWaitingReport,
  downloadRtaPaymentInfo,
  getMilestonePaidReport,
  downPendingScheduleListing,
};
